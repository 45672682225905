import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Avatar, Button, IconButton } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import AddIcon from '@mui/icons-material/Add';
import io from 'socket.io-client'
import SendIcon from '@mui/icons-material/Send';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AudioWave from './AudioWave'
import AppointmentStore from '../DataRepo/AppointmentStore'

function Chat() {
    let [msg, setmsg] = useState('')
    const textAreaRef = useRef(null);
    let { Patient_id, Doctor_id } = useParams()
    const [online, setOnline] = useState([])
    let [messages, setmessages] = useState([])


    const { Appointment_data, getOneAppointment } = AppointmentStore();

    const WS_URL = `wss://a7uoyq8x2j.execute-api.ap-south-1.amazonaws.com/production/?userId=${Patient_id}`;

    let [arrivalMessage, setArrivalMessage] = useState(null);

    let scrollRef = useRef();
    const socket = useRef();


    const sendIconStyle = {
        width: '3rem',
        height: '3rem',
        display: 'flex',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#DA6E0B',
        color: 'white',
    };

    const addIconStyle = {
        width: '3rem',
        height: '3rem',
        display: 'flex',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        border: '1px solid #DA6E0B',
    };



    const { sendJsonMessage, lastJsonMessage, readyState } =
        useWebSocket(WS_URL, {
            onOpen: () => {
                console.log("WebSocket connection established.");
            },
            share: false,
            shouldReconnect: () => true,
        });

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: "subscribe",
                data: {
                    channel: "general-chatroom",
                },
            });
        }
    }, [readyState]);





    useEffect(() => {
        if (lastJsonMessage) {
            console.log(`Got a new message: ${lastJsonMessage}`);
            const { event, data } = lastJsonMessage;
            console.log(data)
            arrivalMessage && setmessages((prev) => [...prev, arrivalMessage]);

        }
    }, [lastJsonMessage]);


    const sendChat = () => {
        if (msg.trim() === '') return; // Prevent sending empty messages

        sendJsonMessage({
            action: "sendMessage",
            message: msg,
            type: "text",
            from: Patient_id,
            to: Doctor_id,
            conversationId: `${Appointment_data?.Appointment_id}`,
        });

        setmsg('');
    };


    let Collectmsgs = async () => {
        try {
            const res = await axios.post('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/getMessages', {
                conversationId: Appointment_data?.Appointment_id
            });

            if (res.status === 200) {
                setmessages(res.data);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        Collectmsgs()
    }, [lastJsonMessage]);

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "20px";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };

    useEffect(
        resizeTextArea,
        [msg]);


    useEffect(() => {
        arrivalMessage && setmessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return <div className='p-0 pb-0' >
        <div style={{ position: "sticky" }} >
            <nav className='flex flex-row p-2 chatnav' >
                <IconButton component={Link} to={`/Appointments`}>
                    <ChevronLeft></ChevronLeft>
                </IconButton>
                <div>
                    <Avatar src={Appointment_data?.doctor_photo} />
                </div>
                <p>{Appointment_data?.doctor_name}</p>

            </nav>
        </div>

        <div className='containes' style={{ position: "-webkit-sticky" }}>
            <div className='chat-box'>
                <div className='chat-body'>
                    {
                        messages.map((e, i) => {
                            return <div
                                key={i}
                                ref={scrollRef}
                                className={e?.from != Patient_id ? "message own" : "message"}
                            >
                                <span>{e.type === "image" ? <img src={e.message}></img>
                                    : e.type === "audio" ? <AudioWave audioUrl={e.message} /> :
                                        e.message

                                }
                                </span>
                                {/* <span>{e?.message.includes('https') ?
                                <a href={e?.message} target='_blank'>
                                    {e?.message}
                                </a> : e?.message}</span> */}
                            </div>
                        })
                    }
                </div>
                <div className='chat-footer'>
                    <div className='chat-footer-action-inputs'>
                        <div className='chat-footer-textarea'>
                            <textarea ref={textAreaRef} placeholder="Message..." value={msg} style={{ color: "black" }}
                                type='text' onChange={(e) => { setmsg(e.target.value) }} row="1" />
                        </div>

                        <div>
                            {
                                msg.length > 0 ?
                                    <Button style={sendIconStyle} onClick={sendChat}>
                                        <SendIcon style={
                                            { color: "#FDFDFD" }
                                        } fontSize='small'></SendIcon>
                                    </Button> :

                                    <Button style={addIconStyle}>
                                        <AddIcon></AddIcon>
                                    </Button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
}

export default Chat