import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AiwellLogo from '../Assets/images/Aiwell Logo 1.png';
import SplashScreenLogo from '../Assets/images/Splash rotation logo.png'
function SplashScreen() {
    let navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/Onboarding')
        }, 2000)
    })

    return <div className='SplashScreen'>

        <div className='Aiwell-splash-logo'>
            <img src={AiwellLogo}>
            </img>
        </div>

        <div className='Splash-rotation-logo'>
            <img src={SplashScreenLogo}>
            </img>
        </div>
    </div>

}

export default SplashScreen