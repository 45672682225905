import React, { useState } from 'react'
import AiwellLogo from '../Assets/images/Aiwell Logo 1.png';
import Lottie from "lottie-react";
import walkthrough1 from '../Assets/animations/Walkthrough 1.json'
import walkthrough2 from '../Assets/animations/Walkthrough 2.json'
import walkthrough3 from '../Assets/animations/walkthrough 3.json'
import walkthrough4 from '../Assets/animations/walkthrough 4 (1).json'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import outerCircle from '../Assets/images/vector.png'
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import { ChairAlt } from '@mui/icons-material';
// import walkthrough4bg from '../Assets/images/Walkthrough 4.png'
function OnBoardingScreen() {

    const WalkThroughSection = [
        {
            name: "W1",
            animation: walkthrough1,
            text: "360&deg; Health Improvement<br/>using Integrative holistic wellness",
            background: false,
        },
        {
            name: "W2",
            animation: walkthrough2,
            text: "Regular monitoring of health status <br/>using digital biomarkers",
            background: false,
        },
        {
            name: "W3",
            animation: walkthrough3,
            text: "Personalized diet & recipes<br/>recommendations",
            background: false,
        },
        {
            name: "W4",
            animation: walkthrough4,
            text: "Holistic health enhancement using<br/>Yoga, Pranayama & Meditation",
            background: true,
        },
    ]

    const [index, setIndex] = useState(0);

    const navigate = useNavigate();

    const ChangeAnimation = () => {
        setIndex(prevIndex => {
            if (prevIndex >= 3) {
                navigate('/Welcome');
                return 0;
            } else {
                return prevIndex + 1;
            }
        });
    };

    const circumference = 2 * Math.PI * 50;
    const offset = circumference - (index / 4) * circumference;

    return <div className='Onboarding-Screen'>
        <div>
            <div className='Aiwell-splash-logo flex flex-row gap-5'>
                <img src={AiwellLogo}>
                </img>
                <p onClick={() => {
                    navigate('/Welcome')
                }}>Skip</p>
            </div>
            <div className={WalkThroughSection[index]?.background ?
                'Onboarding-animation Onboarding-bg' : "Onboarding-animation"}>
                <Lottie animationData={WalkThroughSection[index].animation} loop={false}></Lottie>
            </div>

            <div className='Onboarding-text-area'>
                <p dangerouslySetInnerHTML={{ __html: WalkThroughSection[index].text }} />
            </div>

            <div className='OnBoarding-Next-Btn'>
                <div className="circular-progress-button" onClick={ChangeAnimation}>
                    <div className="outer-circle">
                        <img src={outerCircle} alt="Outer Circle" />
                    </div>
                    <svg className="progress-circle" width="70" height="70">
                        <circle
                            className="background-circle"
                            cx="35"
                            cy="35"
                            r="30"
                            strokeWidth="10"
                        />
                        <circle
                            className="progress"
                            cx="35"
                            cy="35"
                            r="30"
                            strokeWidth="10"
                            strokeDasharray={circumference}
                            strokeDashoffset={offset}
                        />
                    </svg>
                    <div className="button-content">
                        <div className="arrow">
                            {
                                index >= 3 ? <DoneIcon /> : <ArrowForwardIcon />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default OnBoardingScreen