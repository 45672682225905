import create from 'zustand';
import { profileModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

const GoalsStore = create((set) => ({
    Goals: null,
    isGoalsLoading: true,
    error: null,

    getGoal: async () => {
        try {
            const userDetails = getUserDetails();
            let res = await profileModel.get(`/getGoal/${userDetails?.UID}`);

            if (res.status === 200) {
                set({
                    Goals: res.data,
                    isGoalsLoading: false,
                    error: null,
                });
            } else {
                set({
                    error: `Error: ${res.status}`,
                    isGoalsLoading: false,
                });
            }
        } catch (err) {
            if (err.response?.status === 404) {
                set({
                    Goals: null,
                    isGoalsLoading: false,
                    error: err?.response?.data,
                });
            } else {
                set({
                    Goals: null,
                    isGoalsLoading: false,
                    error: err?.response?.data,
                });
            }
            console.log(err?.response?.data);
        }
    },


}));

export default GoalsStore;
