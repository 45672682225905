import React from 'react'
import { getUserDetails } from '../Auth/AuthUtil'
import DoshaChart from './Chart'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import goalsettingimg from '../Assets/images/goal setting.png'
import Slider from 'react-slick';
import { Card, CardContent, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css";
import cautionimg from '../Assets/images/Caution icon 1.png'
import "slick-carousel/slick/slick-theme.css";
import fibpro from '../Assets/images/flbpro.png'
import { ChevronRight } from 'lucide-react'
import bspro from '../Assets/images/bspro.png'
import WellnessStore from '../DataRepo/WellnessStore'
import { useNavigate } from 'react-router-dom'
import leftvec from '../Assets/images/left vector.png';
import rightvec from '../Assets/images/right vector.png';
import yogalandingimg from '../Assets/images/Yoga_Landingpage.png'
import pranayamalandingimg from '../Assets/images/Pranayama.png'
import meditationlandingimg from '../Assets/images/MeditationL.png'
import { profileModel } from '../Axios/AxiosConfig'
import doctorvectorimgcard from '../Assets/images/doctor_card_vector.png'
import ShopStore from '../DataRepo/ShopStore'

function HomePage() {
    const userDetails = getUserDetails()
    const { getTopProducts, Top_Products } = ShopStore();
    const [isprofileloading, setprofileloading] = useState(true)
    const [ProfileData, setProfileData] = useState([])
    const { Diets, getDiet, isDietLoading, getRecipes, Recipes, isRecipeLoading } = WellnessStore();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const [currentRindex, setRindex] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        getDiet()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % Diets?.Suitable?.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [Diets?.Suitable?.length]);


    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }



    useEffect(() => {
        const intervalId = setInterval(() => {
            setRindex((prevIndex) => (prevIndex + 1) % Recipes?.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [Recipes?.length]);

    useEffect(() => {
        getTopProducts()
        getProfile(userDetails?.UID)
    }, [])


    const testKitSlider = [
        {
            id: fibpro,
            title: 'Track your Glucose effortlessly with our user-friendly CGM',
            description: 'Free style libre pro',
            code: '9545784',
        },
        {
            id: bspro,
            title: 'Discover Your Health Answers with our innovative kit!',
            description: 'BugSpeaks',
            code: '9545783',
        }
    ];





    useEffect(() => {
        if (Recipes.length === 0) {
            getRecipes()
        }
    }, [])

    return <div className='Homepage-main-screen'>
        <div className='Homepage-head-section'>
            <p>Hello, {ProfileData?.firstname}. Have a Nice Day!</p>
            <div className='Goal-main-page-card flex flex-row justify-between mt-2 relative' style={{ zIndex: 0 }}>
                <div>
                    <p>Achieve Your Wellness Goal!</p>
                    <p>Set a goal, track your progress, and earn up to 100 coins along the way!</p>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                border: 'none',
                                boxSizing: 'border-box',
                                color: "#fdfdfd",
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}

                            onClick={() => {
                                navigate('/GoalsResult')
                            }
                            }
                        >
                            Click Here
                        </Button>
                    </div>
                </div>

                <div className='Goal-main-page-card-wellness-img'>
                    <img src={goalsettingimg}></img>
                </div>

                <div className='absolute bottom-[-1rem] takevbtn'>
                    <div className='flex flex items-center justify-evenly' onClick={() => {
                        navigate('/VikritiIntro')
                    }}>
                        <p>Take your Vikriti Assessment</p>
                        <ChevronRight />
                    </div>


                </div>
            </div>

            <div className='mt-5 Diet-home-reSection'>

                {Diets?.Suitable?.length > 0 &&
                    <div>
                        <p>Diet</p>
                        <div className='Diet-card-infinite-loop' onClick={() => {
                            navigate(`/Diet?categoryname=${Diets?.Suitable?.[currentIndex]?.categoryEng}`)
                        }}>
                            <div>
                                <p>{Diets?.Suitable?.[currentIndex]?.englishName}</p>
                                <p>{Diets?.Suitable?.[currentIndex]?.categoryEng}</p>
                            </div>
                            <div>
                                <img src={Diets?.Suitable?.[currentIndex]?.imageLink}></img>
                            </div>
                        </div>
                    </div>
                }

                {Recipes.length > 0 &&
                    <div>
                        <p>Recipes</p>
                        <div className='Diet-card-infinite-loop' onClick={() => {
                            navigate(`/Recipes?id=${Recipes?.[currentRindex]?.id}`)
                        }}>
                            <div>
                                <p>{Recipes?.[currentRindex]?.recipe}</p>
                                <p>{Recipes?.[currentIndex]?.benefits}</p>
                            </div>
                            <div>
                                <img src={Recipes?.[currentRindex]?.imageLink}></img>
                            </div>
                        </div>
                    </div>
                }

            </div>

            <div className='mt-5 Practices-section'>
                <div>
                    <img src={leftvec} alt="Left Arrow" />
                    <p>Harmony Within</p>
                    <img src={rightvec} alt="Right Arrow" />
                </div>
                <div className='Practices-main-content-section'>
                    <div>
                        <div onClick={() => {
                            navigate('/Yogas')
                        }}>
                            <img src={yogalandingimg}></img>
                            <p>Yoga</p>
                        </div>
                        <div onClick={() => {
                            navigate('/Pranayamas')
                        }}>
                            <img src={pranayamalandingimg}></img>
                            <p>Pranayama</p>
                        </div>
                        <div onClick={() => {
                            navigate('/Meditations')
                        }}>
                            <img src={meditationlandingimg}></img>
                            <p>Meditation</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 Docter-section-landing-section'>
                <div>
                    <img src={doctorvectorimgcard}></img>
                </div>
                <div>
                    <p>Make your </p>
                    <p><b>Health journey </b></p>
                    <p>much easier </p>
                </div>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            fontSize: 'clamp(10px, 2vw, 14px)',
                            padding: '8px',
                        }}

                        onClick={() => {
                            navigate('/TeleConsultation')
                        }
                        }
                    >
                        Consult now
                    </Button>
                </div>
            </div>

            <div className='mt-5 Top-Products-landing-section'>
                <div>
                    <p>Top Products</p>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        navigate('/Products?type=All')
                    }}>View all</p>
                </div>

                <div>
                    {
                        Top_Products.map((e, i) => {
                            return <div className='Top-Products-landing-sectionpcard' style={{ cursor: "pointer" }} key={i} onClick={() => {
                                navigate(`/Product?id=${e?.id}`)
                            }}>
                                <div>
                                    <img src={e?.imgurl}></img>
                                </div>

                                <div>
                                    <p>{e?.name}</p>
                                    <p>{e?.desc}</p>
                                    <div className='Product-section-price-details'>
                                        <p className='mr-3'><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                        <p>₹ {e?.price}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>


            <div className='mt-5' style={{ display: "none" }}>
                <Slider {...settings}>
                    {testKitSlider.map(item => (
                        <div className='testkitslidecard flex flex-row'>
                            <div>
                                <img src={item.id}></img>
                            </div>
                            <div className='testkitslidecard-info'>
                                <p>{item.title}</p>
                                <div>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            borderRadius: '0',
                                            backgroundColor: '#8CEB8C',
                                            color: '#3B362A',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}

                                        onClick={() => {
                                            //   navigate('/SignUp')
                                        }
                                        }
                                    >
                                        Explore here
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='mt-10'>
                <div className='Slot-errreponse'>
                    <div className='flex flex-col items-center'>
                        <img src={cautionimg}></img>
                        <p style={{ fontWeight: 500, fontSize: 'large' }}>Disclaimer</p>
                    </div>
                    <p>Recommendations are approved by our Ayurveda physician Dr.Prathiban. The assessment and suggestions provided here are for health and wellness purposes only. Please do not self-medicate. Consult an Ayurveda practitioner or medical professional for accurate guidance.</p>
                </div>
            </div>

        </div>
    </div>
}

export default HomePage