import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Box, CircularProgress, Autocomplete, TextField, Chip, Button, Checkbox } from '@mui/material';
import VikritiStore from '../DataRepo/VikiritiStore';
import WellnessStore from '../DataRepo/WellnessStore';
import vikritinext from '../Assets/images/vikritinext.png'
import { profileModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';

function VikritiPage() {

    const { Vikriti_data, isVikriti_dataloading, getVikritidata } = VikritiStore();
    
    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [selectedMainSymptoms, setMainSelectedSymptoms] = useState([]);
    const [selectedCustomSymptoms, setSelectedCustomSymptoms] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [currentSubCategoryIndex, setCurrentSubCategoryIndex] = useState(0);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [currentSubCategoryToDisplay, setCurrentSubCategoryToDisplay] = useState(0);
    const [showSelectedWellness, setShowSelectedWellness] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
    const userDetails = getUserDetails();

    useEffect(() => {
        getVikritidata();
        getSelected_Wellness();
    }, []);

    const handleAutocompleteChange = (event, value) => {
        if (value != null) {
            const symptomValue = value?.trim();
            const existingSymptom = filteredVikritiData?.find(item => item.symptoms.toLowerCase() === symptomValue.toLowerCase());

            if (symptomValue && !selectedSymptoms?.some((item) => item.symptoms === symptomValue) && !selectedCustomSymptoms.some((item) => item.symptoms === symptomValue)) {
                let newSymptomObj;

                if (existingSymptom) {
                    newSymptomObj = {
                        ...existingSymptom,
                        sub_category: existingSymptom.sub_category || 'na',
                        category: existingSymptom.category || 'na',
                        dhatu_increase: existingSymptom.dhatu_increase || 'na',
                        dhatu_decrease: existingSymptom.dhatu_decrease || 'na',
                        dosha_increase: existingSymptom.dosha_increase || 'na',
                        dosha_decrease: existingSymptom.dosha_decrease || 'na',
                        mala_increase: existingSymptom.mala_increase || 'na',
                        mala_decrease: existingSymptom.mala_decrease || 'na'
                    };
                    setSelectedSymptoms([...selectedSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                } else {
                    newSymptomObj = {
                        dosha_decrease: "na",
                        symptoms: symptomValue,
                        sub_category: "na",
                        category: "na",
                        dhatu_increase: "na",
                        dosha_increase: "na",
                        id: Math.random().toString(36).substr(2, 9),
                        mala_increase: "na",
                        mala_decrease: "na",
                        dhatu_decrease: "na"
                    };

                    setSelectedCustomSymptoms([...selectedCustomSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                setInputValue('');
            }
        }
    };

    const handleItemClick = (symptomObj) => {
        if (!selectedSymptoms.some((item) => item.symptoms === symptomObj.symptoms)) {
            setSelectedSymptoms([...selectedSymptoms, symptomObj]);
            setMainSelectedSymptoms([...selectedMainSymptoms, symptomObj]);
        }
    };

    const isCompleted = () => {
        return currentLevel === 4
    }

    const circumference = 2 * Math.PI * 50;
    const progress = (currentLevel / 4) * 100;
    const offset = circumference - (progress / 100) * circumference;


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symptomValue = event.target.value.trim();
            const existingSymptom = filteredVikritiData?.find(item => item.symptoms.toLowerCase() === symptomValue.toLowerCase());

            if (symptomValue && !selectedSymptoms.some((item) => item.symptoms === symptomValue) && !selectedCustomSymptoms.some((item) => item.symptoms === symptomValue)) {

                let newSymptomObj;

                if (existingSymptom) {
                    newSymptomObj = {
                        ...existingSymptom,
                        sub_category: existingSymptom.sub_category || 'na',
                        category: existingSymptom.category || 'na',
                        dhatu_increase: existingSymptom.dhatu_increase || 'na',
                        dhatu_decrease: existingSymptom.dhatu_decrease || 'na',
                        dosha_increase: existingSymptom.dosha_increase || 'na',
                        dosha_decrease: existingSymptom.dosha_decrease || 'na',
                        mala_increase: existingSymptom.mala_increase || 'na',
                        mala_decrease: existingSymptom.mala_decrease || 'na'
                    };
                    setSelectedSymptoms([...selectedSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                else {
                    newSymptomObj = {
                        dosha_decrease: "na",
                        symptoms: symptomValue,
                        sub_category: "na",
                        category: "na",
                        dhatu_increase: "na",
                        dosha_increase: "na",
                        id: Math.random().toString(36).substr(2, 9),
                        mala_increase: "na",
                        mala_decrease: "na",
                        dhatu_decrease: "na"
                    };
                    setSelectedCustomSymptoms([...selectedCustomSymptoms, newSymptomObj]);
                    setMainSelectedSymptoms([...selectedMainSymptoms, newSymptomObj]);
                }

                setInputValue('');
            }
        }
    };

    const handleDelete = (symptomToDelete) => {
        setSelectedSymptoms(selectedSymptoms?.filter((symptom) => symptom?.symptoms !== symptomToDelete?.symptoms));
        setSelectedCustomSymptoms(selectedCustomSymptoms?.filter((symptom) => symptom?.symptoms !== symptomToDelete?.symptoms));
    };

    const UpdateVkikriti = async (scores) => {
        try {
            let res = await profileModel.post('/UpdateVikriti', {
                UID: userDetails?.UID,
                count: selectedMainSymptoms?.length,
                date: Date?.now()?.toString(),
                vataAssScore: scores?.vataAssScore,
                pittaAssScore: scores?.pittaAssScore,
                kaphaAssScore: scores?.kaphaAssScore,
                vikritiList: selectedMainSymptoms,
                vataPulseScore: 0,
                pittaPulseScore: 0,
                kaphaPulseScore: 0,
            })

            if (res?.status === 200 && res?.data) {
                navigate('/VikritiResult')
            }
        }

        catch (err) {
            console.log(err)
        }
    }


    // console.log(showSelectedWellness)

    const handleContinue = () => {

        if (currentLevel === 1) {
            if (selectedCustomSymptoms.length > 0 && selectedSymptoms.length === 0) {
                setShowSelectedWellness(true)
            }
            else {
                setShowSelectedWellness(false)
            }

            setCurrentLevel(2);

        } 
        
        else if (currentLevel === 2 && filteredSubCategories.length > 0) {
            if (currentSubCategoryIndex < filteredSubCategories.length - 1) {
                setCurrentSubCategoryIndex(currentSubCategoryIndex + 1);
            } else {
                setCurrentLevel(3);
            }
        } 
        
        else if (currentLevel === 3) {
            if (selectedSubCategories.length > 0) {
                setCurrentSubCategoryToDisplay(0);
                setCurrentLevel(4);
            }
            else {
                UpdateVkikriti(calculateDoshaValues(selectedSymptoms));
            }

        } 

        else if (currentLevel === 4) {
            if (currentSubCategoryToDisplay < selectedSubCategories.length - 1) {
                setCurrentSubCategoryToDisplay(currentSubCategoryToDisplay + 1);
            } else {
                UpdateVkikriti(calculateDoshaValues(selectedSymptoms));
            }
        }

    };

    function calculateDoshaValues(vikritiResponse) {
        let v = 0;
        let p = 0;
        let k = 0;

        const uniqueCategories = new Set();

        vikritiResponse.forEach(vikriti => {
            vikriti.dosha_increase.split(",").forEach(dosha => {
                switch (dosha.trim().toLowerCase()) {
                    case 'v':
                        v++;
                        break;
                    case 'p':
                        p++;
                        break;
                    case 'k':
                        k++;
                        break;
                }
            });

            vikriti.dosha_decrease.split(",").forEach(dosha => {
                switch (dosha.trim().toLowerCase()) {
                    case 'v':
                        v--;
                        break;
                    case 'p':
                        p--;
                        break;
                    case 'k':
                        k--;
                        break;
                }
            });

            uniqueCategories.add(vikriti.category);
        });


        const totalDoshas = vikritiResponse.length;


        const vPercent = (v / totalDoshas) * 100;
        const pPercent = (p / totalDoshas) * 100;
        const kPercent = (k / totalDoshas) * 100;

        return { vataAssScore: v, pittaAssScore: p, kaphaAssScore: k }
    }

    const handleSymptomToggle = (symptomObj) => {
        const symptomExists = selectedMainSymptoms?.some((item) => item?.symptoms === symptomObj?.symptoms);
        if (symptomExists) {
            setMainSelectedSymptoms(selectedMainSymptoms?.filter((item) => item?.symptoms !== symptomObj?.symptoms));
        } else {
            setSelectedSymptoms([...selectedSymptoms, symptomObj]);
            setMainSelectedSymptoms([...selectedMainSymptoms, symptomObj])
        }
    };

    const handleSubCategoryToggle = (subCategory) => {
        if (selectedSubCategories.includes(subCategory)) {
            setSelectedSubCategories(selectedSubCategories.filter((item) => item !== subCategory));
        } else {
            setSelectedSubCategories([...selectedSubCategories, subCategory]);
        }
    };

    const filteredVikritiData = Vikriti_data?.filter(item => item?.sub_category?.toLowerCase() !== 'na');

    const categories = filteredVikritiData?.filter((s) => Selected_Wellness?.filter((i) => i?.selected).some((e) => s?.category === e?.name));

    const groupedCategories = categories.reduce((acc, symptom) => {
        const { sub_category } = symptom;
        if (!acc[sub_category]) {
            acc[sub_category] = [];
        }
        acc[sub_category].push(symptom);
        return acc;
    }, {});

    const groupedFilteredVikritiData = filteredVikritiData
        ?.filter((s) => selectedSymptoms?.some((e) => e?.sub_category === s?.sub_category))
        ?.reduce((acc, symptom) => {
            const { sub_category } = symptom;
            if (!acc[sub_category]) {
                acc[sub_category] = [];
            }
            acc[sub_category]?.push(symptom);
            return acc;
        }, {});

    const groupedBySubCategory = showSelectedWellness
        ? groupedCategories
        : groupedFilteredVikritiData;

    const filteredSubCategoriesForCategories = Object?.keys(groupedCategories);

    const filteredSubCategoriesForFilteredVikritiData = Object?.keys(groupedFilteredVikritiData);

    const filteredSubCategories = showSelectedWellness
        ? filteredSubCategoriesForCategories
        : filteredSubCategoriesForFilteredVikritiData;

    const currentSubCategory = filteredSubCategories[currentSubCategoryIndex] || filteredSubCategories[0];

    const getUniqueSymptoms = () => {
        const symptomsSet = new Set();
        filteredVikritiData?.forEach(item => {
            item?.symptoms?.split(',')?.forEach(symptom => {
                symptomsSet?.add(symptom?.trim()?.toLowerCase());
            });
        });
        return Array.from(symptomsSet);
    };

    const uniqueSymptoms = getUniqueSymptoms();

    return (
        <div className='Wellness-Info-section'>
            <nav>
                <div className='flex flex-row items-center gap-5 p-5'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <p>Imbalance Assessment</p>
                </div>
            </nav>
            {isVikriti_dataloading ? (
                <div className='Prakriti-container flex flex-col items-center justify-center' style={{ height: "30rem" }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{ color: "#DA6E0B" }} />
                    </Box>
                </div>
            ) : (
                <div>

                    {currentLevel === 1 && (
                        <div className='Prakriti-container' style={{ position: 'relative' }}>
                            {filteredVikritiData?.length > 0 && (
                                <div>
                                    <div className='scroll-container'>
                                        <div className='scroll-content'>
                                            {filteredVikritiData?.slice(0, Math?.ceil(filteredVikritiData?.length / 3))?.map((e, index) => (
                                                <div key={index} className='scroll-item' onClick={() => handleItemClick(e)}>
                                                    {e.symptoms}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='scroll-container'>
                                        <div className='scroll-content'>
                                            {filteredVikritiData?.slice(Math?.ceil(filteredVikritiData?.length / 3), Math?.ceil(2 * filteredVikritiData?.length / 3))?.map((e, index) => (
                                                <div key={index} className='scroll-item' onClick={() => handleItemClick(e)}>
                                                    {e?.symptoms}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='scroll-container'>
                                        <div className='scroll-content'>
                                            {filteredVikritiData?.slice(Math?.ceil(2 * filteredVikritiData?.length / 3))?.map((e, index) => (
                                                <div key={index} className='scroll-item' onClick={() => handleItemClick(e)}>
                                                    {e?.symptoms}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <Autocomplete
                                            freeSolo
                                            options={uniqueSymptoms
                                                ?.filter(symptom =>
                                                    !selectedSymptoms?.find(selected =>
                                                        selected?.symptoms?.toLowerCase() === symptom
                                                    )
                                                )
                                                ?.filter(symptom =>
                                                    symptom?.toLowerCase()?.includes(inputValue?.toLowerCase())
                                                )?.map((e) => e?.charAt(0).toUpperCase() + e?.slice(1))
                                            }

                                            value={inputValue}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                            onChange={handleAutocompleteChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    value={inputValue}
                                                    placeholder="Enter your complaints"
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            )}
                                        />

                                        {(selectedSymptoms?.length > 0 || selectedCustomSymptoms?.length > 0) && (
                                            <div className='selected-symptoms' style={{ marginTop: '20px' }}>
                                                <p>Complaints</p>
                                                {[...selectedSymptoms, ...selectedCustomSymptoms]?.map((symptomObj, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={symptomObj.symptoms}
                                                        onDelete={() => handleDelete(symptomObj)}
                                                        style={{ margin: '5px' }}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {/* {(selectedSymptoms.length > 0 || selectedCustomSymptoms.length > 0 || selectedSubCategories.length > 0) && (
                            
                            )} */}
                            <div onClick={handleContinue} style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon'>
                                <img src={vikritinext}></img>
                            </div>
                        </div>
                    )}

                    {currentLevel === 2 && filteredSubCategories?.length > 0 && (
                        <div className='Prakriti-container' style={{ position: 'relative' }}>
                            <div className='Display-by-subcategory'>
                                <h3>
                                    Do you experience any of the following discomfort in{' '}
                                    {currentSubCategory.charAt(0).toUpperCase() + currentSubCategory.slice(1)}
                                    ?
                                </h3>
                                <div className='selected-symptoms-category'>
                                    {groupedBySubCategory[currentSubCategory]?.map((symptomObj, i) => (
                                        <div
                                            key={i}
                                            onClick={() => handleSymptomToggle(symptomObj)}
                                            style={{
                                                padding: '5px',
                                                border: `1px solid ${selectedMainSymptoms.some((item) => item.symptoms === symptomObj.symptoms) ? "#DA6E0B" : "#ccc"}`,
                                                borderRadius: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Checkbox
                                                checked={selectedMainSymptoms.some((item) => item.symptoms === symptomObj.symptoms)}
                                                style={{ marginRight: '10px', color: `${selectedMainSymptoms.some((item) => item.symptoms === symptomObj.symptoms) ? "#DA6E0B" : "#ccc"}` }}
                                                color="primary"
                                            />
                                            {symptomObj.symptoms.charAt(0).toUpperCase() + symptomObj.symptoms.slice(1)}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {(selectedSymptoms.length > 0 || selectedCustomSymptoms.length > 0 || selectedSubCategories.length > 0) && (
                                <div onClick={handleContinue} style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon'>
                                    <img src={vikritinext}></img>
                                </div>
                            )}

                        </div>

                    )}

                    {currentLevel === 3 && (
                        <div className='Prakriti-container' style={{ position: 'relative' }}>
                            <div className='Display-by-subcategory'>
                                <h3>Do you experience any of the following discomfort ?</h3>
                                <div className='selected-symptoms-category'>
                                    {filteredVikritiData?.map((item) => item?.sub_category)
                                        ?.filter((subCategory, index, self) => self?.indexOf(subCategory) === index && !selectedSymptoms?.some((item) => item?.sub_category === subCategory))?.map((subCategory, i) => (
                                            <div
                                                key={i}
                                                onClick={() => handleSubCategoryToggle(subCategory)}
                                                style={{
                                                    padding: '5px',
                                                    border: selectedSubCategories?.includes(subCategory) ? '2px solid #DA6E0B' : '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={selectedSubCategories?.includes(subCategory)}
                                                    style={{ marginRight: '10px', color: `${selectedSubCategories?.includes(subCategory) ? "#DA6E0B" : "#ccc"}` }}
                                                    color="primary"
                                                />
                                                {subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {(selectedSymptoms?.length > 0 || selectedCustomSymptoms?.length > 0 || selectedSubCategories?.length > 0) && (
                                <div onClick={handleContinue} style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon'>
                                    <img src={vikritinext}></img>
                                </div>

                            )}
                        </div>
                    )}

                    {currentLevel === 4 && selectedSubCategories?.length > 0 && (
                        <div className='Prakriti-container' style={{ position: 'relative' }}>
                            <div className='Display-by-subcategory'>
                                <h3>Do you experience any of the following discomfort in {selectedSubCategories[currentSubCategoryToDisplay].charAt(0).toUpperCase() + selectedSubCategories[currentSubCategoryToDisplay].slice(1)}?</h3>
                                <div className='selected-symptoms-category'>
                                    {filteredVikritiData?.filter((e) => e?.sub_category === selectedSubCategories[currentSubCategoryToDisplay])?.map((symptomObj, i) => (
                                        <div
                                            key={i}
                                            onClick={() => handleSymptomToggle(symptomObj)}
                                            style={{
                                                padding: '5px',
                                                border: `1px solid ${selectedSymptoms?.some((item) => item?.symptoms === symptomObj?.symptoms) ? "#DA6E0B" : "#ccc"}`,
                                                borderRadius: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Checkbox
                                                checked={selectedSymptoms.some((item) => item.symptoms === symptomObj.symptoms)}
                                                style={{ marginRight: '10px', color: `${selectedSymptoms.some((item) => item.symptoms === symptomObj.symptoms) ? "#DA6E0B" : "#ccc"}` }}
                                                color="primary"
                                            />
                                            {symptomObj?.symptoms?.charAt(0)?.toUpperCase() + symptomObj.symptoms.slice(1)}
                                        </div>
                                    ))}
                                </div>

                                {/* <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                    <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePreviousInLevel4}
                                        disabled={currentSubCategoryToDisplay === 0}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton
                                        // variant="contained"
                                        color="primary"
                                        onClick={handleNextInLevel4}
                                        disabled={currentSubCategoryToDisplay === selectedSubCategories.length - 1}
                                    >
                                        <ChevronRightIcon />
                                    </IconButton>
                                </div> */}
                            </div>


                            {(selectedSymptoms.length > 0 || selectedCustomSymptoms.length > 0 || selectedSubCategories.length > 0) && (
                                <div onClick={handleContinue} style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon'>
                                    <img src={vikritinext}></img>
                                </div>

                            )}
                        </div>
                    )}

                </div>
            )}
        </div>
    );
}

export default VikritiPage;
