import React, { useState, useEffect } from 'react';
import WellnessStore from '../DataRepo/WellnessStore';
import { IconButton, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import meditationLandingimg from '../Assets/images/meditation.png'
import loadingimg from '../Assets/images/loading image 1.png'
import MobileStepper from '@mui/material/MobileStepper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwipeableViews from 'react-swipeable-views';

function Yoga() {

    const { getYoga, selectedYogas, unselectedYogas, isYogaLoading } = WellnessStore();


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getYoga()
    }, [])


    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';

    const filteredYoga = [...selectedYogas, ...unselectedYogas]?.filter((e) => e.id === id)

    const [activeStep, setActiveStep] = useState(0);





    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>{filteredYoga?.[0]?.title}</p>
            </div>
        </nav>

        {isYogaLoading ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                    <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={loadingimg} style={{ width: "80%", height: "80%" }} alt="Loading" />
                    </div>
                </div>
            </div>

            : <div className='Yoga-steps-section'>
                <div>
                    <div className='yogafullimg'>
                        <img src={filteredYoga[0]?.fullimage}></img>
                    </div>
                    <div>
                        <p>
                            {filteredYoga?.[0]?.title}
                        </p>
                        <p>
                            {filteredYoga?.[0]?.pose}
                        </p>
                        <p>
                            {filteredYoga?.[0]?.info}
                        </p>
                    </div>
                </div>

                <div className='mt-2 Yoga-step-list-card'>
                    {
                        filteredYoga?.[0]?.steps?.map((e, i) => {
                            return <div className='Yoga-step-card'>
                                <div>
                                    <p>Step {i + 1}</p>
                                </div>
                                <div>
                                  <img src={e?.src}></img>
                                </div>
                                <div>
                                   <p>{e?.name}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
    </div>
}

export default Yoga