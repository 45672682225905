// src/App.js
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import SplashScreen from './Compontents/OnBoarding/SplashScreen';
import OnBoardingScreen from './Compontents/OnBoarding/OnBoardingScreen';
import Welcome from './Compontents/OnBoarding/Welcome';
import Login from './Compontents/Auth/Login';
import SignUp from './Compontents/Auth/SignUp';
import HomeScreen from './Compontents/Home/HomeScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './Compontents/Auth/ProtectedRoute';
import { refreshToken, isTokenExpired } from './Compontents/Auth/AuthUtil';
import Profile from './Compontents/Profile/Profile';
import Wellness from './Compontents/Profile/Wellness';
import WellnessInfo from './Compontents/Profile/WellnessInfo';
import PrakritiIntro from './Compontents/Prakriti/PrakritiIntro';
import Prakriti from './Compontents/Prakriti/Prakriti';
import PrakritiResult from './Compontents/Prakriti/PrakritiResult';
import VikritiIntro from './Compontents/Vikriti/VikritiIntro';
import ShopScreen from './Compontents/Shop/ShopScreen';
import AllProducts from './Compontents/Shop/AllProducts';
import ProductPage from './Compontents/Shop/ProductPage';
import Cart from './Compontents/Shop/Cart';
import Address from './Compontents/Shop/Address';
import Tele from './Compontents/Tele_Consultation/Tele'
import BookAppointment from './Compontents/Tele_Consultation/BookAppointment';
import ConfirmSlot from './Compontents/Tele_Consultation/ConfirmSlot';
import Appointments from './Compontents/Tele_Consultation/Appointments';
import AppointmentPage from './Compontents/Tele_Consultation/AppointmentPage';

import Chat from './Compontents/Chat/Chat';
import HomePage from './Compontents/Home/HomePage';
import VikritiPage from './Compontents/Vikriti/VikritiPage';
import VikritiResult from './Compontents/Vikriti/VikritiResult';
import Diet from './Compontents/Diet/Diet';
import MeditationPage from './Compontents/Meditation/MeditationPage';
import Meditation from './Compontents/Meditation/Meditation';
import RecipePage from './Compontents/Recipes/RecipePage';
import YogaPage from './Compontents/Yoga/YogaPage';
import Yoga from './Compontents/Yoga/Yoga';
import PranayamaPage from './Compontents/Pranayama/PranayamaPage';
import Pranayama from './Compontents/Pranayama/Pranayama';
import DietPage from './Compontents/Diet/DietPage';
import SearchPage from './Compontents/Shop/SearchPage';
import ProfilePage from './Compontents/Profile/ProfilePage';
import EditProfile from './Compontents/Profile/EditProfile';
import Orders from './Compontents/Shop/Orders';
import OrdersPage from './Compontents/Shop/OrdersPage';
import Settings from './Compontents/Settings/Settings';
import Policy from './Compontents/Settings/Policy';
import HelpNSupport from './Compontents/Settings/Help&Support';
import Reports from './Compontents/Reports/Reports';
import UploadReports from './Compontents/Reports/UploadReports';
import ViewReport from './Compontents/Reports/ViewReport';
import ViewReportList from './Compontents/Reports/ViewReportList';
import Prescription from './Compontents/Tele_Consultation/Prescription';
import ViewPrescription from './Compontents/Tele_Consultation/ViewPrescription';
import ForgotPassword from './Compontents/Auth/ForgotPassword';
import ReccomedatedProducts from './Compontents/Shop/ReccomedatedProducts';
import DoctorReview from './Compontents/Tele_Consultation/DoctorReview';
import Wishlist from './Compontents/Shop/Wishlist';
import CreditsPage from './Compontents/Rewards/CreditsPage';
import GoalPage from './Compontents/Goals/GoalPage';
import GoalResult from './Compontents/Goals/GoalResult';

const App = () => {

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>

        // OnBoarding
        <Route path='/SplashScreen' element={<SplashScreen />} />
        <Route path='/Onboarding' element={<OnBoardingScreen />} />
        <Route path='/Welcome' element={<Welcome />} />

        // Auth
        <Route path='/Login' element={<Login />} />
        <Route path='/SignUp' element={<SignUp />} />
        <Route path='/ForgotPassword/:id' element={<ForgotPassword />} />

        // Main page
        <Route path='/' element={<ProtectedRoute><HomeScreen /></ProtectedRoute>} >
          <Route path='/' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path='/Shop' element={<ProtectedRoute><ShopScreen /></ProtectedRoute>} />
          <Route path='/TeleConsultation' element={<ProtectedRoute><Tele /></ProtectedRoute>} />
          <Route path='/DietSection' element={<ProtectedRoute><DietPage /></ProtectedRoute>} />
          <Route path='/MyOrders' element={<ProtectedRoute><Orders /></ProtectedRoute>} />
          <Route path='/Credits' element={<ProtectedRoute><CreditsPage /></ProtectedRoute>} />
          <Route path='/Goals' element={<ProtectedRoute><GoalPage /></ProtectedRoute>} />
          <Route path='/GoalsResult' element={<ProtectedRoute><GoalResult /></ProtectedRoute>} />
        </Route>

        // Shop
        <Route path='/Products' element={<ProtectedRoute><AllProducts /></ProtectedRoute>} />
        <Route path='/Product' element={<ProtectedRoute><ProductPage /></ProtectedRoute>} />
        <Route path='/Checkout' element={<ProtectedRoute><Cart /></ProtectedRoute>} />
        <Route path='/Address' element={<ProtectedRoute><Address /></ProtectedRoute>} />
        <Route path='/Search' element={<ProtectedRoute><SearchPage /></ProtectedRoute>} />
        <Route path='/RecommedatedProducts' element={<ProtectedRoute><ReccomedatedProducts /></ProtectedRoute>} />
        <Route path='/Order' element={<ProtectedRoute><OrdersPage /></ProtectedRoute>} />
        <Route path='/Wishlist' element={<ProtectedRoute>
          <Wishlist />
        </ProtectedRoute>} />

        //Tele
        <Route path='/BookAppointment' element={<ProtectedRoute><BookAppointment /></ProtectedRoute>} />
        <Route path='/ConfirmSlot' element={<ProtectedRoute><ConfirmSlot /></ProtectedRoute>} />
        <Route path='/DoctorReview' element={<DoctorReview />} />
        <Route path='/Appointments' element={<ProtectedRoute><Appointments /></ProtectedRoute>} />
        <Route path='/Appointment' element={<ProtectedRoute><AppointmentPage /></ProtectedRoute>} />
        <Route path='/Prescriptions' element={<ProtectedRoute><Prescription /></ProtectedRoute>} />
        <Route path='/ViewPrescription/:id' element={<ProtectedRoute><ViewPrescription /></ProtectedRoute>} />

        // Chat
        <Route path="/Chat/:Patient_id/:Doctor_id" element={<ProtectedRoute><Chat /></ProtectedRoute>} />


        // Profile
        <Route path='/Profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path='/EditProfile' element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
        <Route path='/Profilepage' element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path='/Wellness' element={<ProtectedRoute><Wellness /></ProtectedRoute>} />
        <Route path='/WellnessInfo' element={<ProtectedRoute><WellnessInfo /></ProtectedRoute>} />
        <Route path='/PrakritiIntro' element={<ProtectedRoute><PrakritiIntro /></ProtectedRoute>} />
        <Route path='/Prakriti' element={<ProtectedRoute><Prakriti /></ProtectedRoute>} />
        <Route path='/PrakritiResult' element={<ProtectedRoute><PrakritiResult /></ProtectedRoute>} />
        <Route path='/VikritiIntro' element={<ProtectedRoute><VikritiIntro /></ProtectedRoute>} />
        <Route path='/Vikriti' element={<ProtectedRoute><VikritiPage /></ProtectedRoute>} />
        <Route path='/VikritiResult' element={<ProtectedRoute><VikritiResult /></ProtectedRoute>} />
        <Route path='/Diet' element={<ProtectedRoute><Diet /></ProtectedRoute>} />
        <Route path='/Recipes' element={<ProtectedRoute><RecipePage /></ProtectedRoute>} />
        <Route path='/Meditations' element={<ProtectedRoute><MeditationPage /></ProtectedRoute>} />
        <Route path='/Yogas' element={<ProtectedRoute><YogaPage /></ProtectedRoute>} />
        <Route path='/Yoga' element={<ProtectedRoute><Yoga /></ProtectedRoute>} />
        <Route path='/Pranayamas' element={<ProtectedRoute><PranayamaPage /></ProtectedRoute>} />
        <Route path='/Pranayama' element={<ProtectedRoute><Pranayama /></ProtectedRoute>} />
        <Route path='/Meditation' element={<ProtectedRoute><Meditation /></ProtectedRoute>} />

        // Reports
        <Route path='/Reports' element={<ProtectedRoute><Reports /></ProtectedRoute>} />
        <Route path='/UploadReports/:type' element={<ProtectedRoute><UploadReports /></ProtectedRoute>} />
        <Route path='/ViewReportList/:id' element={<ProtectedRoute><ViewReportList /></ProtectedRoute>} />
        <Route path='/ViewReport/:id/:key' element={<ProtectedRoute><ViewReport /></ProtectedRoute>} />

        // Settings
        <Route path='/Settings' element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path='/Policy' element={<ProtectedRoute><Policy /></ProtectedRoute>} />
        <Route path='/Help&Support' element={<ProtectedRoute><HelpNSupport /></ProtectedRoute>} />

      </Routes>
    </BrowserRouter>
  );
};




export default App;
