import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Button, Dialog, DialogContent, Typography, Box, CircularProgress, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../Auth/AuthUtil';
import AnswerCard from './AnswerCard';
import CircularProgressWithLabel from './CircularProgresswithLabel';
import { profileModel } from '../Axios/AxiosConfig';

const ProgressBar = ({ currentQuestionIndex, totalQuestions }) => {
    const progress = (currentQuestionIndex / totalQuestions) * 100;
    console.log(progress)
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} className="progress-bar">
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress}
                    sx={{
                        height: 10,
                        width: "100%",
                        borderRadius: 5,
                        backgroundColor: '#E0E0E0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#7FD67F',
                        },
                    }}
                />
            </Box>
            <Box sx={{ minWidth: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${Math.round(progress)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const LevelDialogContent = ({ level, percentage, handleCloseDialog, handleSubmitResult }) => {
    return (
        <div className='Prakriti-level-dialog'>
            <p>Excellent!</p>
            <p>You have Completed</p>
            <p>Level {level}</p>
            <div className='level-progress-container'>
                <CircularProgressWithLabel value={percentage} />
            </div>
            <p className='text-center'>Click Continue to win 30 Credits <br /> and more accurate results</p>
            <div className='Prakriti-level-dialog-buttons mt-5'>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: '1px solid #DA6E0B ',
                            color: '#DA6E0B',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px'
                        }}
                        onClick={handleSubmitResult}
                    >
                        View Results
                    </Button>
                </div>
                <div >
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px',
                        }}
                        onClick={handleCloseDialog}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

const FinalDialogContent = ({ percentage, handleSubmitResult }) => {
    return (
        <div className='Prakriti-level-dialog text-center'>
            <p>Thank you!</p>
            <p>Your Prakriti Assessment <br />has been Completed</p>
            <div className='level-progress-container'>
                <CircularProgressWithLabel value={percentage} />
            </div>
            <div className='Prakriti-level-dialog-buttons mt-5'>
                <div >
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B',
                            color: 'white',
                            border: 'none',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: '10px',
                        }}
                        onClick={handleSubmitResult}
                    >
                        View Results
                    </Button>
                </div>
            </div>
        </div>
    );
};

function Prakriti() {
    const navigate = useNavigate();
    const [prakritiData, setPrakritiData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [transition, setTransition] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const userDetails = getUserDetails();
    const [vScore, setVScore] = useState(0);
    const [pScore, setPScore] = useState(0);
    const [kScore, setKScore] = useState(0);
    const [VPK, setVPK] = useState('');

    const totalQuestions = 35;

    const questionsPerLevel = totalQuestions / 4;

    const getPrakritiData = async () => {
        try {
            let res = await profileModel.get(`/Prakriti/${userDetails.UID}`);
            if (res.status === 200) {
                let Prakriti_data = res.data;
                setPrakritiData(Prakriti_data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err?.response?.data);
        }
    };

    const handleAnswerSelect = (answer) => {
        setSelectedAnswer(answer);

        const currentQuestion = prakritiData[currentQuestionIndex];
        const previousAnswer = currentQuestion.Answers.find(a => a.isSelected);

        const updatedAnswers = currentQuestion.Answers.map((a) =>
            a.Answer === answer.Answer ? { ...a, isSelected: true } : { ...a, isSelected: false }
        );

        const updatedQuestion = {
            ...currentQuestion,
            Answers: updatedAnswers
        };

        const updatedPrakritiData = [...prakritiData];
        updatedPrakritiData[currentQuestionIndex] = updatedQuestion;

        updateScores(previousAnswer, answer);
        setPrakritiData(updatedPrakritiData);
        setTransition(true);
        setTimeout(handleNextQuestion);
    };

    const updateScores = (previousAnswer, newAnswer) => {
        if (previousAnswer) {
            const previousScore = parseFloat(previousAnswer.score);
            switch (previousAnswer.vpk.toLowerCase()) {
                case 'v':
                    setVScore(prevScore => parseFloat(prevScore - previousScore));
                    break;
                case 'p':
                    setPScore(prevScore => parseFloat(prevScore - previousScore));
                    break;
                case 'k':
                    setKScore(prevScore => parseFloat(prevScore - previousScore));
                    break;
                default:
                    break;
            }
        }

        const newScore = parseFloat(newAnswer.score);

        switch (newAnswer.vpk.toLowerCase()) {
            case 'v':
                setVScore(prevScore => parseFloat(prevScore + newScore));
                break;
            case 'p':
                setPScore(prevScore => parseFloat(prevScore + newScore));
                break;
            case 'k':
                setKScore(prevScore => parseFloat(prevScore + newScore));
                break;
            default:
                break;
        }
    };

    function findVPK(arrayList) {
        return new Promise((resolve, reject) => {
            try {
                const datamap = {
                    "V": arrayList[0],
                    "P": arrayList[1],
                    "K": arrayList[2]
                };

                const datamap1 = {
                    [arrayList[0]]: "V",
                    [arrayList[1]]: "P",
                    [arrayList[2]]: "K"
                };

                const arrayList1 = [...arrayList];
                arrayList1.sort((a, b) => a - b);

                const v = arrayList1[2];
                const p = arrayList1[1];
                const k = arrayList1[0];

                arrayList.sort((a, b) => a - b);

                if (v - p > 20) {
                    resolve(datamap1[v]);
                } else if (v - p <= 20 && p - k > 5) {
                    if (v === p) {
                        const arrayList2 = [];
                        for (const [key, value] of Object.entries(datamap)) {
                            if (v === value) {
                                arrayList2.push(key);
                            }
                            
                        }
                        resolve(arrayList2[0] + arrayList2[1]);
                    } else {
                        resolve(datamap1[v] + datamap1[p]);
                    }
                } else {
                    resolve(datamap1[v] + datamap1[p] + datamap1[k]);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    function calculatePercentages(vScore, pScore, kScore) {
        const totalScore = vScore + pScore + kScore;
        if (totalScore > 0) {
            const vScorePercentage = ((vScore / totalScore) * 100);
            const pScorePercentage = ((pScore / totalScore) * 100);
            const kScorePercentage = ((kScore / totalScore) * 100);

            return {
                vScorePercentage: parseFloat(vScorePercentage.toFixed(2)),
                pScorePercentage: parseFloat(pScorePercentage.toFixed(2)),
                kScorePercentage: parseFloat(kScorePercentage.toFixed(2))
            };
        } else {
            return {
                vScorePercentage: 0.0,
                pScorePercentage: 0.0,
                kScorePercentage: 0.0
            };
        }
    }

    const handleSubmitResult = async () => {
        try {
            
            const percentages = calculatePercentages(vScore, pScore, kScore);

            const VPk_result = await findVPK([
                percentages?.vScorePercentage,
                percentages?.pScorePercentage,
                percentages?.kScorePercentage
            ]);

            setVPK(VPk_result);

            console.log(VPk_result);

            console.log([vScore, pScore, kScore]);

            updatePrakritiResult(
                percentages?.vScorePercentage,
                percentages?.pScorePercentage,
                percentages?.kScorePercentage,
                VPk_result
            );

        } catch (error) {
            console.error("Error in handleSubmitResult:", error);
        }
    };

    console.log(prakritiData)

    const updatePrakritiResult = async (vata, pitta, kapha, VPk_result) => {
        try {
            let res = await profileModel.post('/UpdatePrakriti', {
                UID: userDetails?.UID,
                result: VPk_result,
                date: Date.now().toString(),
                vata: Math.round(vata),
                pitta: Math.round(pitta),
                kapha: Math.round(kapha),
                rawVata: vScore,
                rawKapha: kScore,
                rawPitta: pScore,
                id: userDetails.UID,
                PrakritiCollection: prakritiData
            })
            
            if (res.status === 200) {
                navigate('/PrakritiResult')
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    const handleNextQuestion = () => {
        if (currentQuestionIndex < totalQuestions) {
            const nextIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(nextIndex);
            const currentQuestion = prakritiData[nextIndex];
            const selected = currentQuestion.Answers.find(answer => answer.isSelected);
            setSelectedAnswer(selected || null);

            const percentageCompleted = Math.round(((nextIndex ) / totalQuestions) * 100);
            const currentLevel = Math.floor(nextIndex / 10) ;

            if ((nextIndex) % 10 === 0) {
                showCompletionDialog(currentLevel, nextIndex  === totalQuestions, percentageCompleted);
            }

        } else {
            showCompletionDialog(4, true, 100);
        }

        setTransition(false);
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            const previousQuestion = prakritiData[currentQuestionIndex - 1];
            const selected = previousQuestion.Answers.find(answer => answer.isSelected);
            setSelectedAnswer(selected || null);
        }
    };

    const showCompletionDialog = (level, isFinal = false, percentageCompleted) => {
        let content = isFinal
            ? <FinalDialogContent percentage={percentageCompleted} handleCloseDialog={handleCloseDialog} handleSubmitResult={handleSubmitResult} />
            : <LevelDialogContent level={level} percentage={percentageCompleted} handleCloseDialog={handleCloseDialog} handleSubmitResult={handleSubmitResult} />;
        setDialogContent(content);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        getPrakritiData();
    }, []);

    const isFirstQuestion = currentQuestionIndex === 0;
    const isLastQuestion = currentQuestionIndex === prakritiData.length - 1;
    const hasSelectedAnswer = selectedAnswer !== null;

    return (
        <div className='Wellness-Info-section'>
            <nav>
                <div className='flex flex-row items-center gap-5 p-5'>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <p>Phenotype Assessment</p>
                </div>
            </nav>

            {isLoading ? (
                <div className='Prakriti-container flex flex-col items-center justify-center' style={{ height: "30rem" }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{ color: "#DA6E0B" }} />
                    </Box>
                </div>
            ) : (
                <div className='Prakriti-container'>
                    {prakritiData.length > 0 && (
                        <div className={`Prakriti-main-section ${transition ? 'transitioning' : ''}`}>
                            <p className='Prakriti-Question text-center'>{prakritiData[currentQuestionIndex]?.Question}</p>
                            <div className='question-content'>
                                {prakritiData[currentQuestionIndex]?.Answers.map((answer, index) => {
                                    return <AnswerCard key={index} answer={answer} handleAnswerSelect={handleAnswerSelect} />;
                                })}
                            </div>

                            <div className='Prakriti-action-section'>
                                <div>
                                    <ProgressBar currentQuestionIndex={currentQuestionIndex} totalQuestions={totalQuestions} />
                                    <p>Overall Progress</p>
                                </div>

                                <div className='navigation-buttons flex flex-row gap-5' style={{ flexDirection: "row" }}>
                                    <div>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: '1px solid #DA6E0B ',
                                                color: '#DA6E0B',
                                                boxSizing: 'border-box',
                                                borderRadius: '5px',
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: '10px',
                                                display: isFirstQuestion ? 'none' : 'inline-block'
                                            }}
                                            onClick={handlePreviousQuestion}
                                        >
                                            Previous
                                        </Button>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            transition: 'opacity 0.3s ease',
                                            opacity: !hasSelectedAnswer || isLastQuestion ? 0 : 1
                                        }}
                                    >
                                        {!hasSelectedAnswer || isLastQuestion ?
                                            <></> :
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    borderRadius: '0',
                                                    backgroundColor: '#DA6E0B',
                                                    color: 'white',
                                                    border: 'none',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '5px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: '10px',
                                                    // display:  'none' : 'inline-block'
                                                }}
                                                onClick={handleNextQuestion}
                                            >
                                                Next
                                            </Button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="completion-dialog-title">
                <DialogContent>
                    {dialogContent}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Prakriti;