import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function WellnessInfo() {
    const navigete = useNavigate()
    const wellnessInfo = [
        {
            wellness: "Respiratory Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Respiratoty.png",
            wellness_points: [
                "Reducing the chances of diseases like asthma, cough, cold, etc",
                "Promoting overall health"
            ]
        },
        {
            wellness: "Mental Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Mental.png",
            wellness_points: [
                "Ability to handle stress",
                "Overcome hardships and challenges",
                "Build connections"
            ]
        },
        {
            wellness: "Gastro Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Gastro.png",
            wellness_points: [
                "Maintaining healthy gut",
                "Preventing abdominal discomforts like acidity, bloating",
                "Strengthening Immunity"
            ]
        },
        {
            wellness: "Hair & Skin Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Hair+%26amp%3B+Skin.png",
            wellness_points: [
                "Maintain Healthier Skin & Hair",
                "Preventing Dandruff and infections"
            ]
        },
        {
            wellness: "Physical Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Physical.png",
            wellness_points: [
                "Exercise",
                "Good nutrition",
                "Proper sleep",
                "Sexual health",
                "Stress control"
            ]
        },
        {
            wellness: "Women Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Women.png",
            wellness_points: [
                "Hormonal and gynecological issues",
                "Bone health and mental health",
                "Healthy weight control"
            ]
        },
        {
            wellness: "Sexual Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Sexual.png",
            wellness_points: [
                "Improved overall health",
                "Preventing Hormonal imbalances"
            ]
        },
        {
            wellness: "Metabolic Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Metabolic.png",
            wellness_points: [
                "Prevention of obesity, diabetes, and heart disease",
                "Improving BMI, blood sugar control and blood pressure"
            ]
        }, {
            wellness: "General",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/General_wellness.png",
            wellness_points: ["Boost your overall health, physical activity, nutrition, and mental well-being"]
        }
    ];

    return <div className='Wellness-Info-section'>
        <nav>
            <div className='flex flex-row items-center gap-5 p-5'>
                <IconButton onClick={() => {
                    navigete(-1)
                }}><ArrowBackIcon /></IconButton>
                <p>About Wellness Categories</p>
            </div>
        </nav>
        <div className='Wellness-info-container'>
            {
                wellnessInfo.map((e, i) => {
                    return <div className='Wellness-Info-card'>
                        <p>{e.wellness}</p>
                        <div className='flex flex-row items-center gap-5 Wellness-Info-Img'>
                            <img src={e.wellness_icon}></img>
                            <ul>
                                {e.wellness_points.map((e, i) => {
                                    return <li key={i}>{e}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}

export default WellnessInfo