import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ShopStore from '../DataRepo/ShopStore'
import { SearchIcon, Split } from 'lucide-react'
import { useState } from 'react'
import WellnessStore from '../DataRepo/WellnessStore'
import { IconButton, InputAdornment, Box, CircularProgress, Autocomplete, TextField, Chip, Button, Checkbox } from '@mui/material';
function SearchPage() {
    const { getSearchedResults, SearchResults, getTopProducts, isLoading, Top_Products, RecentSearches, setRecentSearches, setRemoveRecentSearches } = ShopStore()
    const { Selected_Wellness, getSelected_Wellness } = WellnessStore();
    const navigate = useNavigate()
    const [selectedCategory, setSelectedCategory] = useState("");
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const value = event.target.value.trim().toLowerCase();

            if (SearchResults?.length > 0) {
                const matchedItems = SearchResults.filter((s) => s?.name?.toLowerCase()?.includes(value));

                console.log({ value, matchedItems, SearchResults });

                if (matchedItems.length > 0) {
                    setRecentSearches(matchedItems)
                    navigate(`/Product?id=${matchedItems[0]?.id}`);
                }
            }

            setInputValue('');
        }
    };


    useEffect(() => {
        getSelected_Wellness();
        getTopProducts()
    }, []);



    const handleAutocompleteChange = (event, Value) => {
        if (Value != null) {


            const value = Value.trim().toLowerCase();
            if (SearchResults?.length > 0) {
                const matchedItems = SearchResults.filter((s) => s?.name?.toLowerCase()?.includes(value));

                console.log({ value, matchedItems, SearchResults });

                if (matchedItems.length > 0) {
                    setRecentSearches(matchedItems)
                    navigate(`/Product?id=${matchedItems[0]?.id}`);
                }
            }
            setInputValue('');
        }
    }

    const Categories = [
        { name: "All", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/all_product_icon.png", type: "All" },
        { name: "Tablets", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tablets.png", type: "Tablets" },
        { name: "Powders", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Powders.png", type: "Churna" },
        { name: "Oils", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Oils.png", type: "Oil" },
        { name: "Ghee", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Ghee.png", type: "Ghee" },
        { name: "Decoctions", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Decoctions.png", type: "Kashayam (Decoctions)" },
        { name: "Fermented", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Fermented.png", type: "Arishta" },
        { name: "Miscellaneous", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Miscellaneous.png", type: "Miscellaneous" },
        { name: "Syrups", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Syrups.png", type: "Syrup" },
        { name: "Tea", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tea.png", type: "Tea" }
    ];


    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Search</p>
            </div>

        </nav>

        <div className='Search-section-page mb-10'>
            <Autocomplete
                freeSolo
                options={SearchResults?.filter((e) =>
                    e?.name?.toLowerCase().includes(inputValue.toLowerCase())
                ).map((e) => e.name)} 
                value={inputValue}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        onChange={(e) =>
                            getSearchedResults(
                                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                            )
                        }
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />


            {RecentSearches.length > 0 &&
                <div className='selected-symptoms' style={{ marginTop: '20px' }}>
                    <p>Recent Searches</p>
                    {RecentSearches.map((e, i) => {
                        return <Chip
                            onClick={() => navigate(`/Product?id=${e?.id}`)}
                            key={i}
                            label={e?.name}
                            onDelete={() => setRemoveRecentSearches(e?.id)}
                            style={{ margin: '5px', cursor: "pointer" }}
                        />
                    })

                    }

                </div>}

            <div className='mt-5'>
                <div className='TypesofP'>
                    <p>Type of Products</p>
                    <div className='ListofCategories'>
                        {Categories?.map((e) => (
                            <div key={e.type} onClick={() => {
                                setSelectedCategory(e?.type)
                                navigate(`/Products?type=${e?.type}`)
                            }}>
                                <img
                                    src={e?.img}
                                    style={selectedCategory === e?.type ? { border: "1px solid #DA6E0B" } : {}}
                                    alt={e?.name}
                                />
                                <p>{e?.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='Shop-Wellness-Categories mt-3'>
                    <p>Categories</p>
                    <div className='shop-selected-wellness-categories mt-2'>
                        {Selected_Wellness.map((category, index) => (
                            <div
                                key={index}
                                className={`WellnessCard`}
                                onClick={() => {
                                    navigate(`/Products?category=${category?.name}`)
                                }}
                            >
                                <div className={`WellnessImg`}>
                                    <img src={category.imageUrl} alt={category.name} />
                                </div>
                                <p className='mt-2'>
                                    {category.name}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='mt-5 Top-Products-landing-section'>
                        <div>
                            <p>Top Products</p>
                            <p style={{ cursor: "pointer" }} onClick={() => {
                                navigate('/Products?type=All')
                            }}>View all</p>
                        </div>
                        <div>
                            {
                                Top_Products.map((e, i) => {
                                    return <div className='Top-Products-landing-sectionpcard'
                                        style={{ cursor: "pointer" }}
                                        key={i} onClick={() => {
                                            navigate(`/Product?id=${e?.id}`)
                                        }}>
                                        <div>
                                            <img src={e?.imgurl}></img>
                                        </div>
                                        <div>
                                            <p>{e?.name}</p>
                                            <p>{e?.desc}</p>
                                            <div className='Product-section-price-details'>
                                                <p><del>{e?.orgprice > 0 && `₹ ${e?.orgprice}`}</del></p>
                                                <p>₹ {e?.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default SearchPage