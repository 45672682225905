import React from 'react'
import AiwellLogo from '../Assets/images/Aiwell Logo 1.png';
import { Button } from '@mui/material';
import Lottie from "lottie-react"
import WelcomeOuterRing from '../Assets/animations/welcome screen rotation.json'
import { useNavigate } from 'react-router-dom';
function Welcome() {
    const navigate = useNavigate()

    return <div className='Welcome-page-section'>
        <div>
            <div className='Aiwell-splash-logo'>
                <img src={AiwellLogo}>
                </img>
            </div>
            <div className="Welcome-text-area">
                <div className="Lottie-container">
                    <Lottie animationData={WelcomeOuterRing} className="Lottie-animation" />
                    <p>Start Your <br /> Wellness Journey <br /> Here...</p>
                </div>
            </div>
            <div className='flex flex-col gap-5 Welcome-action-btn'>
                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            backgroundColor: '#DA6E0B', /* Background color */
                            color: 'white', /* Text color for better contrast */
                            border: 'none', /* Remove default border */
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}

                        onClick={() => {
                          navigate('/SignUp')
                        }
                        }
                    >
                        Create New Account
                    </Button>
                </div>

                <div>
                    <Button
                        style={{
                            width: '100%',
                            textTransform: 'none',
                            borderRadius: '0',
                            border: "1px solid #DA6E0B ",
                            color: '#DA6E0B', /* Text color for better contrast */
                            boxSizing: 'border-box',
                            borderRadius: "5px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px"
                        }}
                        onClick={() => {
                            navigate('/Login')
                        }}
                    >
                        Existing user
                    </Button>
                </div>
                
            </div>
        </div>
    </div>
}

export default Welcome