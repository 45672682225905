import React, { useState, useEffect } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import NotificationIcon from '../Assets/images/notification 1.png'
import myAddressIcon from '../Assets/images/My addresses.png'
import privacyicon from '../Assets/images/Privacy Policy.png'
import returnicon from '../Assets/images/Return &amp; Refund Policy (1).png'
import shippingIcon from '../Assets/images/Shipping policy.png'
import Termsicon from '../Assets/images/Terms &amp; Conditions.png'
import helpicon from '../Assets/images/Help &amp; Support.png'
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { NavItem } from 'react-bootstrap'


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));

function Settings() {
    const navigate = useNavigate()
    const [notificationsEnabled, setNotificationsEnabled] = useState(false);

    useEffect(() => {
        // Check initial notification permission status
        if (Notification.permission === 'granted') {
            setNotificationsEnabled(true);
        } else {
            setNotificationsEnabled(false);
        }
    }, []);

    const handleSwitchChange = (event) => {
        if (event.target.checked) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    setNotificationsEnabled(true);
                } else {
                    setNotificationsEnabled(false);
                }
            });
        } else {
            Notification.requestPermission()
            setNotificationsEnabled(false);
        }
    };

    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Settings</p>
            </div>
        </nav>

        <div className='Settings-section'>

            <div>
                <div>
                    <img src={NotificationIcon}></img>
                    <p>Notifications</p>
                </div>
                <div>
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }}
                            checked={notificationsEnabled}
                            onChange={handleSwitchChange} />}
                    />
                </div>
            </div>

            <div>
                <div onClick={() => {
                    navigate('/Address')
                }}>
                    <img src={myAddressIcon}></img>
                    <p>My Addresses</p>
                </div>
            </div>

            <div>
                <div onClick={()=>{
                    navigate(`/Policy?Policy=${1}`)
                }}>
                    <img src={privacyicon}>
                    </img>
                    <p>Privacy Policy</p>
                </div>
            </div>

            <div onClick={()=>{
                    navigate(`/Policy?Policy=${2}`)
                }}>
                <div>
                    <img src={returnicon} />
                    <p>Return & Refund Policy</p>
                </div>
            </div>


            <div onClick={()=>{
                    navigate(`/Policy?Policy=${3}`)
                }}>
                <div>
                    <img src={shippingIcon} />
                    <p>Shipping Policy</p>
                </div>
            </div>

            <div onClick={()=>{
                    navigate(`/Policy?Policy=${4}`)
                }}>
                <div>
                    <img src={Termsicon} />
                    <p>Terms & Conditions</p>
                </div>
            </div>

            <div onClick={()=>{
                navigate('/Help&Support')
            }}>
                <div>
                    <img src={helpicon} />
                    <p>Help & Support</p>
                </div>
                
            </div>

        </div>
    </div>
}

export default Settings