import React, { useState, useEffect } from 'react';
import PersonalInfo from '../Assets/images/personal information.png';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import outerCircle from '../Assets/images/vector.png';
import { getUserAcc, getUserDetails } from '../Auth/AuthUtil';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { profileModel } from '../Axios/AxiosConfig';

function Profile() {
  const genderOptions = ["Male", "Female", "Other"];
  const foodprefernces = ["Vegetarian", "Non-Vegetarian", "Vegan"]

  const userDetails = getUserAcc()

  console.log(userDetails)

  const navigate = useNavigate()
  const cookies = new Cookies();

  const indianLanguages = [
    "Assamese", "Bengali", "Bodo", "Dogri", "Gujarati", "Hindi", "Kannada", "Kashmiri", "Konkani",
    "Maithili", "Malayalam", "Manipuri", "Marathi", "Nepali", "Odia", "Punjabi", "Sanskrit", "Santali",
    "Sindhi", "Tamil", "Telugu", "Urdu"
  ];

  const [formData, setFormData] = useState({
    gender: '',
    motherTongue: '',
    dob: '',
    name: '',
    foodpreference: '',
    heightFeet: '',
    heightInches: '',
    weight: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'dob' ? value.split('-').reverse().join('/') : value,
    }));
  };

  const [fieldsCompleted, setFieldsCompleted] = useState(0);

  const isCompleted = () => {
    return fieldsCompleted === 5
  }

  function extractName(fullName) {
    const nameParts = fullName.trim().split(' ');
    const lastName = nameParts.pop();
    const firstName = nameParts.join(' ');
    return { firstName, lastName };
  }

  console.log(extractName(formData.name))

  const SubmitProfile = async () => {
    if (isCompleted()) {
      try {
        const { firstName, lastName } = extractName(formData.name)

        let res = await profileModel.post('/Profile', {
          UID: getUserDetails()?.UID,

          email: userDetails?.email || "",
          phone: userDetails?.phone || "",

          gender: formData?.gender,
          dob: formData?.dob,
          foodpreference: formData?.foodpreference,
          mothertounge: formData?.motherTongue,
          weight: formData?.weight ? `${formData.weight} Kg` : 'N/A',
          height: formData?.heightFeet || formData?.heightInches
            ? `${formData.heightFeet || 0} feet ${formData.heightInches || 0} inches`
            : 'N/A',

          firstname: firstName,
          lastname: lastName || '',
          src: ""
        })

        if (res.status === 200) {
          cookies.remove('UserDetails')
          navigate('/Wellness')
        }
      }
      catch (err) {
        console.log(err)
      }
    }
  }


  const circumference = 2 * Math.PI * 50;
  const progress = (fieldsCompleted / 7) * 100;
  const offset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    const fields = [formData?.gender, formData?.motherTongue, formData?.heightFeet, formData?.weight, formData?.dob];
    const count = fields.filter(field => field?.trim() !== '')?.length;



    setFieldsCompleted(count);
  }, [formData?.gender, formData?.motherTongue, formData?.heightFeet, formData?.weight, formData?.dob]);

  const fields = [formData?.gender, formData?.motherTongue, formData?.heightFeet, formData?.weight, formData?.dob];

  console.log(fieldsCompleted)

  return (
    <div className='Profile-page-section'>
      <div className='Profile-img-section'>
        <div>Personal Information</div>
        <div><img src={PersonalInfo} alt="Personal Information" /></div>
      </div>

      <div className='Profile-main-section'>

        <div className='Profile-heading-section'>
          <p>Hello there!</p>
          <p>We're delighted that you've begun your health journey! <br /> Please share some details for optimal support.</p>
        </div>


        <div className="Profile-input-container">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData?.name}
            onChange={handleChange}
          />
        </div>

        <div className="Profile-input-container">
          <label>Gender</label>
          <input
            list="gender-options"
            name="gender"
            value={formData?.gender}
            onChange={handleChange}
          />
          <datalist id="gender-options">
            {genderOptions.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
        </div>

        <div className="Profile-input-container">
          <label>Mother Tongue</label>
          <input
            list="mother-tongue-options"
            name="motherTongue"
            value={formData.motherTongue}
            onChange={handleChange}
          />
          <datalist id="mother-tongue-options">
            {indianLanguages.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
        </div>

        <div className="Profile-input-container">
          <label>Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={formData.dob.split('/').reverse().join('-')}
            onChange={handleChange}
          />
        </div>

        <div className="Profile-input-container">
          <label>Height</label>
          <div style={{ display: 'flex', gap: '10px' }}>

            <input
              type="number"
              name="heightFeet"
              value={formData.heightFeet || ''}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  heightFeet: e.target.value,
                }))
              }
              placeholder="Feet"
              min="0"
            />

            <input
              type="number"
              name="heightInches"
              value={formData.heightInches || ''}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  heightInches: e.target.value,
                }))
              }
              placeholder="Inches"
              min="0"
              max="11"
            />
          </div>
        </div>


        <div className="Profile-input-container">
          <label>Weight</label>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <input
              type="number"
              name="weight"
              value={formData.weight || ''}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  weight: e.target.value,
                }))
              }
              placeholder="Weight"
              min="0"
            />
            <span>Kg</span>
          </div>
        </div>



        <div className="Profile-input-container">
          <label>Food Preferences</label>

          <input
            list="foodpreference-options"
            name="foodpreference"
            value={formData.foodpreference}
            onChange={handleChange}
          />

          <datalist id="foodpreference-options">
            {foodprefernces.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
        </div>


        <div className='OnBoarding-Next-Btn' onClick={SubmitProfile}>
          <div className="circular-progress-button">
            <div className="outer-circle">
              <img src={outerCircle} alt="Outer Circle" />
            </div>
            <svg className="progress-circle" width="70" height="70">
              <circle className="background-circle" cx="35" cy="35" r="30" strokeWidth="10" />
              <circle className="progress" cx="35" cy="35" r="30" strokeWidth="10" strokeDasharray={circumference} strokeDashoffset={offset} />
            </svg>
            <div className="button-content">
              <div className="arrow">
                {isCompleted() ? <DoneIcon /> : <ArrowForwardIcon />}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Profile;
