import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import useCartStore from '../DataRepo/CartStore';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import calendaicon from '../Assets/images/Calender.png'
import clock from '../Assets/images/clock.png'
import offerimg from '../Assets/images/offer.png'
import videocall from '../Assets/images/Video Call Orange Color.png'
import audiocall from '../Assets/images/Call Icon Orange Color.png'
import offerApplied from '../Assets/animations/offerapplied.json'
import Lottie from 'lottie-react';
import { Button } from '@mui/material';
import { getUserDetails } from '../Auth/AuthUtil';
import axios from 'axios';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { profileModel } from '../Axios/AxiosConfig';
import pauymentsuccess from '../Assets/Raw/paymentsuccess_ding.mp3';
import success from '../Assets/animations/success lottie.json';

function ConfirmSlot() {
    const { AppointmentDetails, setAppointmentDetails } = TeleConsultationStore();
    const { cartItems, getCouponDetails, Coupons, CouponDetails, setCoupon } = useCartStore();
    const [hash, sethash] = useState('')
    const [msg, setmsg] = useState('na')
    const [coupon, setcoupon] = useState('')
    const [couponError, setCouponError] = useState('')
    const [Transcation_id, setTranscation] = useState('')
    const userDetails = getUserDetails()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [isprofileloading, setprofileloading] = useState(true)
    const [ProfileData, setProfileData] = useState([])

    const ringtone = new Audio(pauymentsuccess)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const getProfile = async (id) => {
        try {
            let res = await profileModel.get(`/Profile/${id}`)
            if (res.status === 200) {
                const profile = res?.data
                setProfileData(profile)
                setprofileloading(false)
            }
        }
        catch (err) {
            console.log(err?.response?.data)
        }
    }

    useEffect(() => {
        if (!AppointmentDetails) {
            navigate(-1)
        }
    }, [AppointmentDetails])

    useEffect(() => {
        getCouponDetails()
        getProfile(userDetails?.UID)
    }, [])

    const handleCoupon = () => {
        let ValidCoupon = Coupons.find((e) => e?.couponName?.toLowerCase()?.trim() === coupon?.toLowerCase()?.trim());

        if (ValidCoupon) {
            setCoupon(ValidCoupon);
        } else {
            setCouponError("Please enter a valid coupon code");
        }

    };

    const Bookappointment = async () => {
        try {
            const res = await teleConsultationModel.post('/UpdateAppointment', {
                appointment_status: "upcoming",
                consultation_status: "pending",
                appointment_date: AppointmentDetails.selectedDay,
                consultation_type: AppointmentDetails.selectedMode,
                appointment_time: AppointmentDetails.selectedTime,
                history_appointment_time: AppointmentDetails.selectedTime,
                history_appointment_date: AppointmentDetails.selectedDay,
                doctor_id: AppointmentDetails.DoctorDetails.PID,
                Paid: ((price) - ((price / 100) * discount)),
                doctor_uid: AppointmentDetails.DoctorDetails.UID,
                patient_id: userDetails?.UID,
                payment_status: "Paid",
                doctor_status: "pending",
            })

            if (res.status === 200) {
                handleClickOpen2()

                ringtone.play()

                setCoupon(null)

                setTimeout(() => {
                    navigate('/Appointments')
                }, 2500)
            }
        }
        catch (err) {
            console.log(err.response)
        }
    }

    const checkout = () => {

        var data = {
            key: 'Ey2EbY',
            hash: hash,
            txnid: Transcation_id,
            amount: ((price) - ((price / 100) * discount)).toString(),
            firstname: ProfileData?.firstname,
            email: ProfileData?.email,
            phone: ProfileData?.phone || "123456790",
            productinfo: 'Aiwell Tele',
            surl: 'http://thirdparty.com/testresponse.php',
            furl: 'http://thirdparty.com/testresponse.php',
        };

        var handlers = {
            responseHandler: function (BOLT) {
                if (BOLT.response.txnStatus == "SUCCESS") {
                    Bookappointment()
                    console.log('Your payment has been successful');
                }
                if (BOLT.response.txnStatus == "FAILED") {
                    console.log('Payment failed. Please try again.');
                }
                if (BOLT.response.txnStatus == "CANCEL") {
                    console.log('Payment failed. Please try again.');
                }
            },
            catchException: function (BOLT) {
                console.log('Payment failed. Please try again.');
            }
        };

        window.bolt.launch(data, handlers);
    }




    const initiatePayment = async () => {
        if (((price) - ((price / 100) * discount)) > 0) {
            try {
                const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
                    {
                        amount: ((price) - ((price / 100) * discount)).toString(),
                        txnid: Date.now(),
                        phone: ProfileData?.phone || "123456790",
                        email: ProfileData?.email,
                        productinfo: "Aiwell Tele",
                        firstname: ProfileData?.firstname,
                    });

                if (response.status === 200) {
                    sethash(response.data.hash)
                    setTranscation(response?.data?.TranscationID)
                    handleClickOpen()
                }
            } catch (error) {
                console?.error('Error initiating payment:', error?.response);
            }
        }

        else {
            Bookappointment()
        }
    };

    const price = parseInt(AppointmentDetails?.price);
    const discount = CouponDetails?.discount || 0;


    return <div className='All-products-section-main'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Confirm Appointment</p>
            </div>
            {/* <div>
                <Badge
                    badgeContent={cartItems?.length}
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: 'red',
                            color: 'white',
                        },
                    }}
                >
                    <div className='BagIcon'>
                        <img src={addtocart} alt="Add to Cart" />
                    </div>
                </Badge>
            </div> */}
        </nav>
        <div className='Appointment-confirm-page'>
            <div className='Appointment-confirm-page-doctor-details'>
                <div>
                    <img src={AppointmentDetails?.DoctorDetails?.img}></img>
                    <div className='Appointment-confirm-page-doctor-info-details'>
                        <div>
                            <p>{AppointmentDetails?.DoctorDetails?.name}
                                &nbsp;
                                <span>{AppointmentDetails?.DoctorDetails?.course_details}</span>
                            </p>
                            <p>{AppointmentDetails?.DoctorDetails?.speciality}</p>
                        </div>

                        <div>
                            <p>Appointment details</p>
                            <div className='Appointment-selected-info'>
                                <div>
                                    <div className='icons'> <img src={calendaicon} ></img></div>
                                    <div><p>{AppointmentDetails?.selectedDay}</p></div>
                                </div>

                                <div>
                                    <div className='icons'>
                                        <img src={clock} ></img>
                                    </div>
                                    <div >
                                        <p>{AppointmentDetails?.selectedTime}</p>
                                    </div>

                                </div>
                                <div>
                                    <div className='icons'>
                                        <img src={AppointmentDetails?.selectedMode === 'Video' ? videocall : audiocall}></img>
                                    </div>
                                    <div>
                                        <p>{AppointmentDetails?.selectedMode}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Appointment-confirm-page-info'>
                <div>
                    <p>Any Message for Doctor</p>
                    <div className='mt-3'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Enter your message"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={(e) => {
                                setmsg(e.target.value)
                            }}
                        />
                    </div>
                </div>

                {!CouponDetails &&
                    <div className="coupon-container mt-5">
                        <input type="text" placeholder="Enter your coupon code" className="coupon-input" onChange={(e) => {
                            setcoupon(e.target.value)
                            setCouponError('')
                        }} />
                        <button className="apply-button" onClick={handleCoupon} >Apply</button>
                    </div>
                }

                {CouponDetails &&
                    <div className="CouponAppliedContainer mt-5">
                        <div className="lottie-background">
                            <Lottie animationData={offerApplied} />
                        </div>
                        <div className="content">
                            <img src={offerimg} alt="Offer" />
                            <div>
                                <p>{CouponDetails?.couponName} Applied</p>
                                <p>You have saved ₹{(price / 100) * discount} by applying a {discount}% discount for this order</p>
                            </div>
                        </div>


                        <IconButton style={{ alignSelf: "flex-start", justifySelf: "flex-end", marginLeft: 'auto' }} onClick={() => {
                            setCoupon(null)
                        }}>
                            <HighlightOffIcon />
                        </IconButton>

                    </div>
                }

                {
                    couponError != '' &&
                    <p className='couponError'>{couponError}</p>
                }

                <div className='Cart-total-container'>
                    <div>
                        <p>Consultation fees</p>
                    </div>
                    <div>
                        <div>
                            <p>Sub Total</p>
                            <p>{`₹ ${price}`}</p>
                        </div>

                        {
                            CouponDetails &&
                            <div>
                                <p>Coupon Discount</p>
                                <p>- ₹{(price / 100) * discount}</p>
                            </div>
                        }
                    </div>
                    <div>
                        <p>Total : <span>₹ {((price) - ((price / 100) * discount))}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ padding: "10px", position: "fixed", bottom: 0, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        backgroundColor: '#DA6E0B',
                        color: '#FDFDFD',
                        border: '1px solid #DA6E0B',
                        boxSizing: 'border-box',
                        paddingRight: "3rem",
                        paddingLeft: "3rem",
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}
                    onClick={() => initiatePayment()}
                >
                    Pay and Confirm
                </Button>
            </div>
        </div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirm Appointment"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to proceed to payment?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={checkout} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className='Appointment-booked-success'>
                    <Lottie animationData={success} loop={false}></Lottie>
                    <p>Appointment Booked Successfully</p>
                </div>
            </DialogContent>
        </Dialog>
    </div>
}

export default ConfirmSlot