import React, { useState, useEffect, useRef, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ShopStore from '../DataRepo/ShopStore';
import { CircularProgress, Button, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { shopModel } from '../Axios/AxiosConfig';
import WishlistStore from '../DataRepo/WishlistStore';

import loadingimg from '../Assets/images/loading image 1.png'
function AllProducts() {
    const { Products, getProducts, isLoading, getProductsByType, page, setPage } = ShopStore();
    const { addWishlist, WishlistItems, isWishlistLoading, removeWishlist, getWishlistItems } = WishlistStore()
    const [selectedCategory, setSelectedCategory] = useState("");
    const [wellnessCategory, setWellnessCategory] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [posts, setPosts] = useState([]);
    // const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const observer = useRef();


    const lastPostElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage();
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading]
    );

    const loadMoreProducts = async () => {
        setLoading(true);
        getProducts()
        setLoading(false);
    };

    useEffect(() => {
        if (selectedCategory === "All" && wellnessCategory === '') {
            loadMoreProducts()
        }
    }, [page, selectedCategory, wellnessCategory]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const category = queryParams.get('category') || '';
        const type = queryParams.get('type') || 'All'
        setWellnessCategory(category.trim());
        setSelectedCategory(type);
        getWishlistItems()
    }, [location]);


    useEffect(() => {
        if (selectedCategory === "All" && wellnessCategory === '') {
            getProducts();
        } else {
            getProductsByType(selectedCategory, wellnessCategory);
        }
    }, [selectedCategory, wellnessCategory]);


    const Categories = [
        { name: "All", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/all_product_icon.png", type: "All" },
        { name: "Tablets", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tablets.png", type: "Tablets" },
        { name: "Powders", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Powders.png", type: "Churna" },
        { name: "Oils", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Oils.png", type: "Oil" },
        { name: "Ghee", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Ghee.png", type: "Ghee" },
        { name: "Decoctions", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Decoctions.png", type: "Kashayam (Decoctions)" },
        { name: "Fermented", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Fermented.png", type: "Arishta" },
        { name: "Miscellaneous", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Miscellaneous.png", type: "Miscellaneous" },
        { name: "Syrups", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Syrups.png", type: "Syrup" },
        { name: "Tea", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tea.png", type: "Tea" }
    ];

    return (
        <div className='All-products-section-main'>
            <nav className='flex flex-row p-2'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p>Products</p>
            </nav>

            <div className="All-products-section">
                <div className='ListofCategories'>
                    {Categories.map((e) => (
                        <div key={e.type} onClick={() => setSelectedCategory(e.type)}>
                            <img
                                src={e.img}
                                style={selectedCategory === e.type ? { border: "1px solid #DA6E0B" } : {}}
                                alt={e.name}
                            />
                            <p>{e.name}</p>
                        </div>
                    ))}
                </div>
                <div className="RecommendedProduct-container">
                    {Products.map((product, i) => (
                        <div key={i} className="Product-section-card"
                            ref={lastPostElementRef}
                            onClick={() => {
                                navigate(`/Product?id=${product.id}`)
                            }}>
                            <div className="Product-card-header">
                                <div className="Product-discount-badge">
                                    {product.orgprice > 0 && product.price && product.orgprice != product.price ? (
                                        <p>
                                            {(((parseFloat(product.orgprice) - parseFloat(product.price)) / parseFloat(product.orgprice)) * 100).toFixed(0) + "% off"}
                                        </p>
                                    ) : null}
                                </div>

                                <div className="Product-wishlist-icon">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            {
                                                WishlistItems.some((item) => item.id === product?.id) ? (
                                                    removeWishlist(product.id)
                                                ) : (
                                                    addWishlist(product.id)
                                                )
                                            }
                                        }}
                                    >
                                        {WishlistItems.some((item) => item.id === product?.id) ? (
                                            <FavoriteIcon style={{
                                                color: "#DA6E0B"
                                            }} />
                                        ) : (
                                            <FavoriteBorderIcon />
                                        )}
                                    </IconButton>
                                </div>

                            </div>
                            <div>
                                <img src={product?.imgurl || loadingimg
                                } alt={product.name} style={{ width: '100%', borderRadius: '10px' }} />
                            </div>

                            <div className='Product-section-card-title'>
                                <p>{product?.name}</p>
                                <p>{product?.desc}</p>
                            </div>

                            <div className='Addtobagbutton'>
                                <div className='Product-section-price-details'>
                                    {product?.orgprice > 0 && product?.orgprice != product?.price &&
                                        <p className='mr-5' style={{ fontSize: "16px" }}><del>{`₹ ${product?.orgprice}`}</del></p>}
                                    <p style={{ fontSize: "18px" }}>₹ {product?.price}</p>
                                </div>
                                <div className='w-100'>
                                    <Button
                                        style={{
                                            width: '100%',
                                            textTransform: 'none',
                                            backgroundColor: '#75623F',
                                            color: 'white',
                                            border: 'none',
                                            boxSizing: 'border-box',
                                            borderRadius: "5px",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            padding: "10px"
                                        }}
                                        onClick={() => {

                                        }}
                                    >
                                        <div className='addtobagctn'>
                                            <p>{product.onsale === true ? 'Add to bag' : "Coming Soon"}</p>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {isLoading &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress style={{
                            color: "#DA6E0B"
                        }} />
                    </div>}
            </div>
        </div >
    );
}

export default AllProducts;