import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TeleConsultationStore from '../DataRepo/TeleConsultationStore';
import DownloadIcon from '@mui/icons-material/Download';
function Prescription() {

  const { id } = useParams()

  const { PrescriptionData, getPrescription, isPrescriptionLoading } = TeleConsultationStore()
  const navigate = useNavigate()

  useEffect(() => {
    getPrescription()
  }, [])

  const handlePrescription = (data) => {
    navigate(`/ViewPrescription/${data.id}`)
  }

  const handleDownload = (s3Link) => {
    const link = document.createElement('a');
    link.href = s3Link;
    link.download = true;
    link.click();
  };

  return <div className='All-products-section-main'>
    <nav className='flex flex-row p-2 items-center justify-between'>
      <div className='flex flex-row items-center'>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
        <p className='mb-0'>Prescription</p>
      </div>
    </nav>
    <div className='Reports-main-page'>

      {PrescriptionData?.length > 0 ?
        <><div className='ListReports'>
          {PrescriptionData?.map((e, i) => {
            return <div className="" style={{ width: "100%" }}
            >

              <div className='Prescription-card' style={{ width: "100%" }}>

                <div className='flex flex-row items-center gap-14'>

                  <div>
                    <img src='/icons/Rx.png' alt='PDF icon' />
                  </div>
                  <div>
                    <p>{e?.Appointment_details?.patient_name}</p>
                    <p>{e.Date}</p>
                    <p>Prescribed by {e?.Appointment_details?.doctor_name}</p>
                  </div>

                </div>

                <div className='flex flex-row justify-between gap-5 mt-5' style={{ width: "100%" }} >

                  <div style={{ width: "30%" }}>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        backgroundColor: '#FDFDFD',
                        color: '#DA6E0B',
                        border: '1px solid #DA6E0B',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                      }}
                      onClick={() => navigate('/TeleConsultation')}
                    >
                      <DownloadIcon onClick={(event) => {
                        event.stopPropagation()
                        handleDownload(e?.Prescription)
                      }} />
                    </Button>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: '10px'
                      }}

                      onClick={() => {
                        handlePrescription(e)
                      }}
                    >
                      View
                    </Button>
                  </div>

                </div>

              </div>

            </div>
          })
          }
        </div>
        </>
        :

        <div className='No-reports'>
          <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/no+prescription.png'></img>
          <p>No Prescription found</p>

          <p className='text-center'>it seems no prescreptions have been added to your account yet.<br />
            Please consult our doctor to get a prescription or check back later if you
            recently had a apppointment
          </p>

          <div>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                backgroundColor: '#FDFDFD',
                color: '#DA6E0B',
                border: '1px solid #DA6E0B',
                boxSizing: 'border-box',
                borderRadius: "5px",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: "10px"
              }}
              onClick={() => navigate('/TeleConsultation')}
            >
              Consult Doctor
            </Button>
          </div>

        </div>
      }

    </div>
  </div>
}

export default Prescription