import React, { useState, useEffect } from 'react'
import { Slider } from '@mui/material'
import { profileModel } from '../Axios/AxiosConfig'
import VikritiIntro from '../Vikriti/VikritiIntro'
import vikritinext from '../Assets/images/vikritinext.png'
import { getUserDetails } from '../Auth/AuthUtil'
import { useNavigate } from 'react-router-dom'

function GoalPage() {

    const [vikritidata, setvikritiData] = useState([])
    const navigate = useNavigate()

    const getVikritidata = async () => {
        try {
            const userDetails = getUserDetails();

            let res = await profileModel.get(`/Vikriti/${userDetails.UID}`);

            if (res?.status === 200 && res?.data) {
                setvikritiData(res?.data?.vikritiList)
            }
            else {
                console.log(`Failed to fetch Vikriti data. Status code: ${res.status}`)
            }

        } catch (err) {
            console.log(err?.response?.data);

        }
    }

    const handleSeverityChange = (index, newValue) => {
        const updatedData = vikritidata.map((item, i) =>
            i === index ? { ...item, severity: newValue } : item
        );
        setvikritiData(updatedData);
    };

    useEffect(() => {
        getVikritidata()
    }, [])


    function calculateAverageSeverity(severityMap) {
        const maxSeverityPerSymptom = 5;
        const totalSymptoms = severityMap.length;
        const maxTotalScore = totalSymptoms * maxSeverityPerSymptom;

        const totalSeverityScore = severityMap.reduce((sum, e) => sum + e.severity, 0);

        if (totalSymptoms === 0 || totalSeverityScore === 0) return 100;


        return Math.round((1 - totalSeverityScore / maxTotalScore) * 100);
    }

    const updateGoal = async () => {
        try {

            const userDetails = getUserDetails();

            let res = await profileModel.post(`/updateGoal`, {
                userId: userDetails?.UID,
                SymptomsList: vikritidata,
                Total: calculateAverageSeverity(vikritidata)
            });

            if (res?.status === 200) {
                navigate('/GoalsResult')
            }
            else {
                console.log(`Failed to fetch Vikriti data. Status code: ${res.status}`)
            }


        }
        catch (err) {
            console.log(err?.response?.data);
        }
    }

    return (
        <div className='Goals-section'>
            <div className='Goals-section-container'>
                <p>Choose your severity of  symptoms</p>

                {
                    vikritidata?.map((e, i) => {
                        return <div className='mt-5 serverity-symptoms-div'>
                            <p>{e?.symptoms}</p>
                            <div style={{ maxWidth: '20rem' }} className='mt-5'>
                                <Slider
                                    step={1}
                                    value={e?.severity}
                                    max={5}
                                    marks={[
                                        { value: 0, label: '0' },
                                        { value: 1, label: '1' },
                                        { value: 2, label: '2' },
                                        { value: 3, label: '3' },
                                        { value: 4, label: '4' },
                                        { value: 5, label: '5' }
                                    ]}
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            color: "#8CEB8C"
                                        },
                                        '& .MuiSlider-track': {
                                            color: "#8CEB8C"
                                        },
                                        '& .MuiSlider-rail': {
                                            color: "#acc4e4"
                                        },
                                        '& .MuiSlider-active': {
                                            color: "#8CEB8C"
                                        }
                                    }}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(value) => {
                                        const imageMap = {
                                            0: 'url(../images/None.png)',
                                            1: 'url(../images/Low.png)',
                                            2: 'url(../images/Moderate.png)',
                                            3: 'url(../images/High.png)',
                                            4: 'url(../images/Severe.png)',
                                            5: 'url(../images/Extreme.png)'
                                        };

                                        return < div
                                            style={{
                                                backgroundImage: imageMap[value],
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: '2rem',
                                                backgroundPosition: "center",
                                                width: '2.5rem',
                                                height: '50px',
                                            }}
                                        >

                                        </div>
                                    }}
                                    color='#DA6E0B'
                                    valueLabelStyle={{ color: '#DA6E0B' }}
                                    onChange={(event, newValue) => handleSeverityChange(i, newValue)}
                                />
                            </div>
                        </div>

                    })
                }

                <div style={{ position: 'fixed', left: '50%', transform: 'translateX(-50%)' }} className='Vikritinextimgicon' onClick={updateGoal}>
                    <img src={vikritinext}></img>
                </div>

            </div>

        </div >


    )
}

export default GoalPage