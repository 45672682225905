import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import outerCircle from '../Assets/images/vector.png';
import wellnessCategoriesImg from '../Assets/images/wellness categories.png';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserDetails } from '../Auth/AuthUtil';
import { profileModel } from '../Axios/AxiosConfig';

function Wellness() {
    const navigete = useNavigate()
    const userDetails = getUserDetails()

    const [allWellnessCategories, setWellnessCategories] = useState([
        {
            name: "Respiratory",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Respiratoty.png",
            selected: false
        },
        {
            name: "Mental",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Mental.png",
            selected: false
        },
        {
            name: "Gastro",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Gastro.png",
            selected: false
        },
        {
            name: "Hair & Skin",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Hair+%26amp%3B+Skin.png",
            selected: false
        },
        {
            name: "Physical",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Physical.png",
            selected: false
        },
        {
            name: "Women",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Women.png",
            selected: false
        },
        {
            name: "Sexual",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Sexual.png",
            selected: false
        },
        {
            name: "Metabolic",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Metabolic.png",
            selected: false
        },
        {

            name: "General",
            imageUrl: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/General_wellness.png",
            selected: false
        }
    ]);

    const SubmitWellnessInterest = async () => {
        try {
            let res = await profileModel.post('/AddwellnessCategories', {
                UID: userDetails?.UID,
                Categories: allWellnessCategories
            })

            if (res.status === 200) {
                navigete('/PrakritiIntro')
            }
        }
        catch (err) {
            console.log(err.response.data)
        }
    }

    const handleCardClick = (index) => {
        setWellnessCategories(prevCategories =>
            prevCategories.map((category, i) =>
                i === index
                    ? { ...category, selected: !category.selected }
                    : category
            )
        );
    };

    return (
        <div className='Profile-page-section'>
            <div className='Profile-img-section'>
                <div>Wellness Categories</div>
                <div><img src={wellnessCategoriesImg} alt="Wellness Categories" /></div>
            </div>

            <div className='Wellness-main-section'>
                <div>
                    <div className='w-full mt-5 flex justify-between items-center'>
                        <IconButton onClick={() => {
                            navigete(-1)
                        }}><ArrowBackIcon /></IconButton>
                        <IconButton onClick={() => {
                            navigete('/WellnessInfo')
                        }}><InfoIcon /></IconButton>
                    </div>

                    <div className='Wellness-categories-section mt-5'>
                        <p>Choose the wellness categories that you like to improve</p>
                        <div className='Wellness-categories-list'>
                            {allWellnessCategories.map((category, index) => (
                                <div
                                    key={index}
                                    className={`WellnessCard`}
                                    onClick={() => handleCardClick(index)}
                                >
                                    <div className={`WellnessImg`}  style={category.selected ? {border:'2px solid #DA6E0B'} : {}}> 
                                        <img src={category.imageUrl} alt={category.name} />
                                    </div>
                                    <p className='mt-2'>
                                        {category.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='OnBoarding-Next-Btn' onClick={SubmitWellnessInterest}>
                    <div className="circular-progress-button">
                        <div className="outer-circle">
                            <img src={outerCircle} alt="Outer Circle" />
                        </div>
                        <svg className="progress-circle" width="70" height="70">
                            <circle className="background-circle" cx="35" cy="35" r="30" strokeWidth="10" />

                        </svg>
                        <div className="button-content">
                            <div className="arrow">
                                <DoneIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Wellness;
